$white: #ffffff;
.bg-white {
  background-color: $white;
}
.c-white {
  color: $white;
}

$gray: #7a7a7a;
.bg-gray {
  background-color: $gray;
}
.c-gray {
  color: $gray;
}

$light-gray: #e0e0e0;
.bg-light-gray {
  background-color: $light-gray;
}
.c-light-gray {
  color: $light-gray;
}

$medium-gray: #a3a3a3;
.bg-light-gray {
  background-color: $medium-gray;
}
.c-light-gray {
  color: $medium-gray;
}

$dark-grey: #292929;
.bg-dark-grey {
  background-color: $dark-grey;
}
.c-dark-grey {
  color: $dark-grey;
}

$strong-grey: #181818;
.bg-dark-grey {
  background-color: $dark-grey;
}
.c-dark-grey {
  color: $dark-grey;
}

$black: #000000;
.bg-black {
  background-color: $black;
}
.c-black {
  color: $black;
}

$dark: #3d3d3d;
.bg-dark {
  background-color: $dark;
}
.c-dark {
  color: $dark;
}

$light-dark: #525252;
.bg-dark {
  background-color: $light-dark;
}
.c-dark {
  color: $light-dark;
}

$dark-green: #00e13a;
.bg-dark-green {
  background-color: $dark-green;
}
.c-dark-green {
  color: $dark-green;
}

//

$dark-yellow: #d6af00;
.bg-dark-yellow {
  background-color: $dark-yellow;
}
.c-dark-yellow {
  color: $dark-yellow;
}

$dark-red: #ff003c;
.bg-dark-red {
  background-color: $dark-red;
}
.c-dark-red {
  color: $dark-red;
}
