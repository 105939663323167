// BREAKPOINTS ***************************************************************

@mixin for-phone-ipad {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: 375px) and (max-width: 767px) {
    @content;
  }
}

@mixin mobile-s {
  @media (min-width: 375px) and (max-width: 480px) {
    @content;
  }
}

@mixin mobile-xl {
  @media (min-width: 481px) and (max-width: 767px) {
    @content;
  }
}

@mixin sinceIpad {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin ipad {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin max-ipad {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1200px) {
    @content;
  }
}

@mixin desktop-xl {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin desktop-xxl {
  @media (min-width: 1201px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1025px) {
    @content;
  }
}

// FONT FACE *********************************************************************

@font-face {
  font-family: titleFont;
  src: url(./../assets/font/DrukTextWide-Medium.otf);
}

@font-face {
  font-family: nextDropFont;
  src: url(./../assets/font/adineueCHOP-Black.otf);
}

@font-face {
  font-family: bodyFontRegular;
  src: url(./../assets/font/Inter-Regular.otf);
}

@font-face {
  font-family: bodyFontMedium;
  src: url(./../assets/font/Inter-Medium.otf);
}

@font-face {
  font-family: bodyFontBold;
  src: url(./../assets/font/Inter-Bold.otf);
}

// FONT MIXING *********************************************************************

@mixin font-xxl {
  font-size: 34px;
  line-height: 64px;
  font-weight: 400;
}

@mixin font-xl {
  font-size: 32px;
  font-weight: 500;
}

@mixin font-x {
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
}

@mixin font-md {
  font-size: 20px;
  font-weight: 400;
  line-height: 26.88px;
  text-transform: uppercase;
}

@mixin font-normal {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}

@mixin font-bold-normal {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

@mixin font-xs {
  font-size: 14px;
  font-weight: 400;
  line-height: 27.24px;
}

@mixin font-xxs {
  font-size: 12px;
  font-weight: 400;
  line-height: 27.24px;
}

@mixin font-xxs-minHeight {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

@mixin font-button-header {
  font-family: titleFont;
  font-size: 12px;
  line-height: 24px;
}

// RESET *********************************************************************

@mixin reset {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: bodyFontRegular;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

// FLEX  ***************************************************************

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-column;
  align-items: center;
}

@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

// BACKGROUND ************************************************************

@mixin cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@mixin contain-background {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@mixin contain-background-y {
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
}
