/* You can add global styles to this file, and also import other style files */
@import "./scss/essentials.scss";

:root {
  --green-main: #00F645;
  --green-second: #00A40C;
  --green-third: #00FFB1;
  --green-fouth: #BEFF00 ;
  --blue-main: #1B00DD;
  --blue-second: #235BFF;
  --blue-third: #4975FF;
  --purple: #780BEE;
  --pink-main: #FF0088;
  --pink-second: #FF0049;
  --pink-third: #FF4171;
  --yellow-main: #E4B000;
  --yellow-second: #FFD100;
  --yellow-third: #FFE24F;
  --orange: #FF6700;
  --white: #FFF;
  --black: #000;
  --gray-first: #111;
  --gray-second: #1F1F1F;
  --gray-third: #636363;
  --global-rem: .8vw;
}


* {
  font-family: "DrukWideText";
  color: var(--white);
  text-transform: uppercase;
}

html {
  overflow: hidden;
  background-image: url("./assets/images/new/Background.svg");
  font-size: var(--global-rem);
  @include for-phone-ipad {
    font-size: 70%;
  }
}

body {
  margin: 0;
}

button {
  cursor: pointer;
}

main {
  background-color: $black;
  color: #fff;
}


// DIALOG MAT STYLES

.uni-ic__header-content-title {
  display: none;
}

.uni-ic__header-content {
  justify-content: flex-end !important;
}

.uni-ic__header-container {
  padding: 0rem 0.25rem 0.5rem !important;

  @include for-phone-ipad {
    padding: 0 0.25rem 0.5rem !important;
  }
}

.mat-dialog-container {
  color: $white;
  height: fit-content !important;
  z-index: 4;
  background-color: var(--gray-second) !important;
  border: 3px solid var(--green-main);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.2rem !important;
  gap: 2rem;
}

.mat-form-field {
  width: 100%;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
  background: #1f1f1f !important;
  border-radius: 4px !important;

  @include for-phone-ipad {
    padding: 0.2em 0.8em 0.2em 0.2em !important;
  }
}

.mat-select-panel {
  background: #292929 !important;
}

.mat-select-value-text {
  font-size: 16px !important;

  @include for-phone-ipad {
    font-size: 14px !important;
  }
}

input.mat-input-element {
  font-size: 16px !important;

  @include for-phone-ipad {
    font-size: 14px !important;
  }
}

.mat-menu-panel {
  background: #292929 !important;
  .mat-menu-item {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: white !important;
  }
}

.mat-option-text {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
}

.mat-form-field-ripple {
  background-color: #7a7a7a !important;
}

.mat-select-value {
  color: #fff !important;
}

.arrow .mat-select-arrow {
  color: #fff !important;
}
.arrow .mat-select-arrow-wrapper {
  color: #fff !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: transparent !important;
}

::ng-deep .mat-form-field-underline {
  display: none;
  background-color: transparent !important;
  color: transparent;
}

.center .mat-simple-snackbar {
  justify-content: center;
}

//UNISWAP

.mat-dialog-container .general-container .mat-dialog-content {
  padding: 0px 30px 0px 30px !important;
  overflow-y: hidden;

  @include for-phone-ipad {
    padding: 0px 10px !important;
    margin-left: -10px !important;
  }
}
.mat-dialog-container {
  @include for-phone-ipad {
    padding: 10px 16px !important;
  }
}

.uni-ic__swap-container {
  @include for-phone-ipad {
    padding: 0px !important;
  }
}

.swap-container {
  margin: 5% 20% !important;
  background-color: #141414 !important;
  border: 1px solid #292929;

  @include for-phone-ipad {
    margin: 5% 4% !important;
  }
}

.general-container .container-header .close_icon {
  @include for-phone-ipad {
    margin-right: 0px !important;
  }
}

.uni-ic__swap-input-container {
  border-radius: 8px !important;
  background-color: #000 !important;
}

.uni-ic__swap-output-container {
  border-radius: 4px !important;
  background-color: #000 !important;
}

.uni-ic__theme-background {
  background: #000 !important;
}

.uni-ic__swap-input-content {
  border-radius: 6px !important;
}

.uni-ic__swap-output-content {
  border-radius: 6px !important;
}

.uni-ic__swap-input-content-main {
  padding: 12px 12px 12px 12px !important;

  @include for-phone-ipad {
    padding: 8px !important;
  }
}

.uni-ic__modal__content {
  border-radius: 8px !important;
}

.uni-ic__swap-output-content-main {
  padding: 12px 12px 12px 12px !important;

  @include for-phone-ipad {
    padding: 6px !important;
  }
}

.uni-ic__swap-content-balance-and-price-container {
  @include for-phone-ipad {
    padding: 8px !important;
  }
}

.uni-ic__swap-input-content-main-from-currency {
  background-color: #292929 !important;
  border-radius: 4px !important;
  padding: 8px !important;
  width: 150px !important;
  justify-content: flex-start !important;

  @include for-phone-ipad {
    width: 100px !important;
  }
}

.uni-ic__swap-input-content-main-from {
  @include for-phone-ipad {
    font-size: 16px !important;
  }
}

.uni-ic__swap-input-content-main-from-currency-symbol {
  font-size: 18px !important;
  text-transform: left !important;

  @include for-phone-ipad {
    font-size: 12px !important;
    margin: 0 !important;
  }
}

.uni-ic__swap-content-balance-and-price-container {
  color: #959292 !important;
  font-family: bodyFontRegular !important;
  font-size: 10px !important;
}

.uni-ic__swap-content-balance-and-price__balance-text,
.uni-ic__swap-content-balance-and-price__price {
  @include for-phone-ipad {
    font-weight: 500 !important;
    font-size: 12px !important;
    letter-spacing: 0.5px !important;
  }
}

.uni-ic__swap-input-content-main-from-currency-icon {
  display: none !important;
}

.uni-ic__modal-confirm-swap__input-main__symbol,
.uni-ic__modal-confirm-swap__output-main__symbol .lib-token-icon {
  display: none;
}

.uni-ic__swap-input-content-main-from-currency-container {
  @include for-phone-ipad {
    padding: 0 !important;
  }
}

.uni-ic__swap-output-content-main-from-currency-container {
  @include for-phone-ipad {
    padding: 0 !important;
  }
}

.uni-ic__swap-divider {
  margin-top: -6px !important;
  margin-bottom: -12px !important;
}

.uni-ic__swap-button {
  border-radius: 4px !important;
  background-color: #00e13a !important;
  color: #000 !important;
  height: 40px;
}

.uni-ic__swap-button-text {
  font-size: 12px !important;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  font-family: titleFont;
}

.uni-ic__swap-button:disabled {
  background-color: #322f2f !important;
  color: #525252 !important;
}

.uni-ic__swap-quote-price-text {
  color: #959292 !important;

  @include for-phone-ipad {
    font-size: 12px !important;
    letter-spacing: 0.5px !important;
  }
}

.uni-ic__swap-divider svg {
  stroke: #fff !important;
}

.uni-ic__header-content-settings-button svg {
  @include for-phone-ipad {
    height: 18px !important;
  }
}

.uni-ic__header-content-settings-button {
  @include for-phone-ipad {
    padding: 0px !important;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32) !important;
  backdrop-filter: blur(5px);
}

.uni-ic__modal-tokens-item-container.selected {
  background: #00e13a !important;
  color: #000 !important;
}

// SETTINGS UNISWAP STYLES

.uni-ic__settings-transaction-slippage-option.selected {
  background: $dark-green !important;
  color: $black !important;
}

.uni-ic__settings-transaction-slippage-option {
  border: 1px solid #292929 !important;
  border-radius: 4px !important;
  background: #292929 !important;
  color: #fff !important;

  @include for-phone-ipad {
    font-size: 12px !important;
  }
}

.uni-ic__settings-transaction-slippage-manual {
  border-radius: 4px !important;
  background: #292929 !important;
  color: #fff !important;
  border: 1px solid #292929 !important;

  @include for-phone-ipad {
    padding: 0 !important;
  }
}

.uni-ic__settings-transaction-slippage-manual-input {
  background: #292929 !important;
  color: #fff !important;
}

.uni-ic__settings-transaction-slippage-manual-input::placeholder {
  @include for-phone-ipad {
    font-size: 12px !important;
  }
}

.uni-ic__settings-transaction-deadline-minute-button {
  border-radius: 4px !important;
  border: 1px solid #292929 !important;
  background: #292929 !important;
}

.uni-ic__settings-transaction-deadline-minute-input {
  border-radius: 0 !important;
  background: #292929 !important;
  color: $white;

  @include for-phone-ipad {
    font-size: 12px !important;
  }
}

.uni-ic__settings-interface-multihops-actions {
  border-radius: 4px 0 !important;
  background: #292929 !important;
}

.uni-ic__settings-interface-multihops-actions-on {
  border-radius: 4px 0 !important;
  background: #292929 !important;
}

.uni-ic__settings-interface-multihops-actions-off,
.uni-ic__settings-interface-multihops-actions-on {
  color: $white !important;
}

.uni-ic__settings-interface-multihops-actions-off.selected,
.uni-ic__settings-interface-multihops-actions-on.selected {
  background: $dark-green !important;
  color: $black !important;
  border-radius: 4px !important;
}

.uni-ic__settings-interface-multihops-actions-off,
.uni-ic__settings-interface-multihops-actions-on {
  border-radius: 4px !important;
  background: #292929 !important;
  color: $white !important;

  @include for-phone-ipad {
    font-size: 12px;
  }
}

.uni-ic__settings-transaction-slippage-title {
  color: #e0e0e0 !important;
  font-size: 12px !important;
  line-height: 28.3px !important;
  font-weight: 500 !important;
  letter-spacing: 2% !important;
  font-weight: 500 !important;
  font-family: bodyFontRegular !important;

  @include for-phone-ipad {
    font-size: 10px !important;
    line-height: 22px !important;
  }
}

.uni-ic__settings-interface-header {
  font-weight: 700 !important;
  font-size: 16px !important;
  margin-top: 7% !important;

  @include for-phone-ipad {
    font-size: 12px !important;
    line-height: 22px;
  }
}

.uni-ic__settings-transaction-title {
  font-weight: 700 !important;
  font-size: 16px !important;

  @include for-phone-ipad {
    font-size: 12px !important;
    line-height: 22px;
  }
}

.uni-ic__settings {
  @include for-phone-ipad {
    margin-left: 15% !important;
    row-gap: 0px !important;
    background: #000 !important;
    border: 1px solid #292929 !important;
    border-radius: 4px !important;
  }

  @include ipad {
    row-gap: 0px !important;
    background: #000 !important;
    border: 1px solid #292929 !important;
  }
}

.uni-ic__settings-transaction-deadline-title {
  @include for-phone-ipad {
    font-size: 10px !important;
    line-height: 22px !important;
  }
}

.uni-ic__settings-interface-multihops-text {
  font-size: 12px !important;

  @include for-phone-ipad {
    font-size: 10px !important;
  }
}

.uni-ic__settings-interface-multihops-actions-off,
.uni-ic__settings-interface-multihops-actions-on {
  @include for-phone-ipad {
    font-size: 12px !important;
  }
}

.uni-ic__settings-transaction-slippage-manual {
  @include for-phone-ipad {
    font-size: 12px !important;
  }
}

.uni-ic__settings-transaction-deadline-minute-label {
  @include for-phone-ipad {
    font-size: 12px !important;
  }
}

//CONFIRM DIALOG SWAP

.uni-ic__modal-confirm-swap__info__item__value {
  color: #7a7a7a !important;
  font-size: 15px !important;

  @include for-phone-ipad {
    font-size: 10px !important;
  }
}

.uni-ic__modal-confirm-swap__action__button {
  border-radius: 4px !important;
  background-color: $dark-green !important;
  color: $black !important;
  margin: 10px 0 0;
  height: 56px !important;

  @include for-phone-ipad {
    height: 40px !important;
  }
}

.uni-ic__modal-confirm-swap__action__button-text {
  font-size: 16px !important;
  font-family: titleFont !important;
  text-transform: uppercase;

  @include for-phone-ipad {
    font-size: 12px !important;
  }
}

.uni-ic__swap-allow {
  border-radius: 4px !important;
  background-color: $dark-green !important;
  color: $black !important;
}

.uni-ic__modal-transaction__state__loading svg path {
  stroke: $dark-green !important;
}

.uni-ic__modal-transaction__state__info-confirmation {
  font-size: 16px !important;
  font-family: titleFont !important;
  text-transform: uppercase;

  @include for-phone-ipad {
    font-size: 12px !important;
  }
}

.uni-ic__modal-transaction__state__action button {
  border-radius: 4px !important;
  background-color: $dark-green !important;
  color: $black !important;
}

.uni-ic__modal-transaction__state__action button {
  border-radius: 4px !important;
  background-color: $dark-green !important;
  color: $black !important;
  padding: 10px !important;
  font-family: titleFont !important;
  text-transform: uppercase;

  @include for-phone-ipad {
    font-size: 12px !important;
  }
}

// UNISWAP SPINNER STYLES

.uni-ic__loading svg circle {
  stroke-width: 4px !important;
}

.uni-ic__loading__svg {
  stroke: $dark-green !important;
}

.uni-ic__loading__svg.bg {
  stroke: $white !important;
}

.mat-form-field-underline {
  position: absolute;
  width: 100%;
  pointer-events: none;
  transform: scale3d(1, 1.0001, 1);
}

.mat-option-text {
  display: flex !important;
  align-content: center;
  align-items: center;
}

.mat-option {
  padding: 0 0 0 16px !important;
}

.mat-label {
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  font-family: titleFont !important;
  border: 2px solid yellow !important;
}

.mat-form-field-type-mat-select .mat-form-field-label {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-transform: uppercase !important;
  font-family: titleFont !important;
}

//FILTER LINE

@include for-phone-ipad {
  .mat-select-arrow {
    color: $white !important;
  }

  .mat-select-panel-wrap {
    margin-top: 10% !important;
  }

  .mat-option.mat-active {
    background: #000 !important;
  }

  .mat-select-panel .mat-optgroup-label,
  .mat-select-panel .mat-option {
    background: #141414 !important;
    border-top: 1px solid #292929;
  }

  .cdk-overlay-pane {
    font-size: 20px !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #000 !important;
  }

  .mat-form-field-underline {
    bottom: 0 !important;
  }

  .mat-form-field-underline {
    width: 0% !important;
  }
}

//TOGGLE STYLES

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #00e13a !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #f6f6f6 !important;
}

.mat-slide-toggle-bar {
  background-color: #525252 !important;
  border: 1px solid #656565;
}

//BOTTOM SHEET STYLES

.mat-bottom-sheet-container {
  background-color: #141414 !important;
  padding: 0px !important;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid #292929;
}

.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  cursor: pointer;
  background-color: #141414 !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-form-field-infix {
  border-top: 0em solid transparent !important;
}

.mat-select-panel .mat-option {
  height: 7em !important;

  span {
    display: flex;
    flex-direction: column;
  }
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(-50%);
  margin-top: 30px;
}

.cdk-overlay-pane {
  bottom: 0.5px !important;
}

.mat-drawer.mat-drawer-side {
  z-index: 1 !important;
}
