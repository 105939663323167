@font-face {
  font-family: "DrukWideText";
  src: local("DrukWideText"),
  url("../assets/font/DrukTextWide-Medium-Trial.otf") format("truetype");
}

@font-face {
  font-family: "DrukWideTextHeavy";
  src: local("DrukWideTextHeavy"),
  url("../assets/font/DrukTextWide-Heavy-Trial.otf") format("truetype");
}

@font-face {
  font-family: "DrukWideTextItalic";
  src: local("DrukWideTextItalic"),
  url("../assets/font/DrukTextWide-MediumItalic-Trial.otf")
  format("truetype");
}

/* GT Flexa */
@font-face {
  font-family: "GTFlexaCompressedLight";
  src: local("GTFlexaCompressedLight"),
  url("../assets/font/GT-Flexa-Compressed-Light.otf")
  format("truetype");
}
@font-face {
  font-family: "GTFlexaCompressedRegular";
  src: local("GTFlexaCompressedRegular"),
  url("../assets/font/GT-Flexa-Compressed-Regular.otf")
  format("truetype");
}
@font-face {
  font-family: "GTFlexaCompressedMedium";
  src: local("GTFlexaCompressedMedium"),
  url("../assets/font/GT-Flexa-Compressed-Medium.otf")
  format("truetype");
}
@font-face {
  font-family: "GTFlexaCompressedBold";
  src: local("GTFlexaCompressedBold"),
  url("../assets/font/GT-Flexa-Compressed-Bold.otf")
  format("truetype");
}
/* Regular*/
@font-face {
  font-family: "GTFlexaXCompressedLight";
  src: local("GTFlexaXCompressedLight"),
  url("../assets/font/GT-Flexa-Compressed-Light.otf")
  format("truetype");
}
@font-face {
  font-family: "GTFlexaXCompressedRegular";
  src: local("GTFlexaXCompressedRegular"),
  url("../assets/font/GT-Flexa-X-Compressed-Regular.otf")
  format("truetype");
}
@font-face {
  font-family: "GTFlexaXCompressedMedium";
  src: local("GTFlexaXCompressedMedium"),
  url("../assets/font/GT-Flexa-X-Compressed-Medium.otf")
  format("truetype");
}
@font-face {
  font-family: "GTFlexaXCompressedBold";
  src: local("GTFlexaXCompressedBold"),
  url("../assets/font/GT-Flexa-X-Compressed-Bold.otf")
  format("truetype");
}
/* Italic */
@font-face {
  font-family: "GTFlexaXCompressedItalicLight";
  src: local("GTFlexaXCompressedItalicLight"),
  url("../assets/font/GT-Flexa-X-Compressed-Light-Italic.otf")
  format("truetype");
}
@font-face {
  font-family: "GTFlexaXCompressedItalicRegular";
  src: local("GTFlexaXCompressedItalicRegular"),
  url("../assets/font/GT-Flexa-X-Compressed-Regular-Italic.otf")
  format("truetype");
}
@font-face {
  font-family: "GTFlexaXCompressedItalicMedium";
  src: local("GTFlexaXCompressedItalicMedium"),
  url("../assets/font/GT-Flexa-X-Compressed-Medium-Italic.otf")
  format("truetype");
}
@font-face {
  font-family: "GTFlexaXCompressedItalicBold";
  src: local("GTFlexaXCompressedItalicBold"),
  url("../assets/font/GT-Flexa-X-Compressed-Bold-Italic.otf")
  format("truetype");
}
